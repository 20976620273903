<template>
  <div>
    <v-form v-if="!registered" ref="form" v-model="valid" lazy-validation>
      <div>
        {{ $t('loginPage.pages.register.text') }}
        <br />
        <br />
      </div>
      <div v-if="error">
        {{ error }}
        <br />
        <br />
      </div>
      <v-text-field
        outlined
        v-model="model.name"
        :label="$tt.capitalize($t('loginPage.name'))"
        :rules="rules.name"
        placeholder=""
        name="name"
      />
      <v-text-field
        v-model="model.email"
        outlined
        :label="$tt.capitalize($t('loginPage.email'))"
        :rules="rules.email"
        placeholder=""
        name="email"
      />
      <v-text-field
        v-model="model.password"
        outlined
        counter
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :label="$tt.capitalize($t('loginPage.password'))"
        :rules="rules.password"
        :type="showPassword ? 'text' : 'password'"
        placeholder=""
        name="password"
        @click:append="showPassword = !showPassword"
      />
      <v-text-field
        v-model="passwordConfirmation"
        outlined
        counter
        :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
        :label="$tt.capitalize($t('loginPage.passwordConfirmation'))"
        :rules="rules.passwordConfirmation"
        :type="showPasswordConfirmation ? 'text' : 'password'"
        placeholder=""
        name="passwordConfirmation"
        @click:append="showPasswordConfirmation = !showPasswordConfirmation"
      />
      <v-select
        name="country"
        v-model="model.country"
        item-value="_id"
        item-text="name"
        :items="countryOptions"
        :label="$tt.capitalize($t('loginPage.country'))"
        :rules="rules.country"
        @change="getStates()"
        outlined
      />
      <v-select
        name="state"
        v-if="model.country"
        v-model="model.state"
        :items="states"
        :label="$tt.capitalize($t('labels.states'))"
        :rules="rules.state"
        outlined
      />
      <v-select
        name="master"
        v-if="model.country"
        v-model="model.master"
        :rules="rules.master"
        :items="masterOptions"
        :label="$tt.capitalize($t('loginPage.master'))"
        outlined
      />

      <div class="d-flex align-center">
        <v-checkbox
          name="privacy"
          v-model="model.accept"
          style="margin-top: 0 !important"
          :rules="rules.accept"
          ref="checkbox-accept"
        />
        <p :class="{ 'has-error': acceptHasError }">
          {{ $t('loginPage.accept.text')
          }}<a href="/privacy-policy" target="_blank">{{
            $t('loginPage.accept.link')
          }}</a>
        </p>
      </div>
      <div
        v-show="acceptHasError"
        class="hacked-message v-messages theme--light error--text"
        role="alert"
      >
        <div class="v-messages__wrapper">
          <div class="v-messages__message">Required</div>
        </div>
      </div>

      <v-btn
        name="register"
        depressed
        block
        color="primary"
        @click="submit"
        :disabled="sending"
        :loading="sending"
      >
        {{ $t('labels.send') }}
      </v-btn>
    </v-form>
    <div v-else>
      <p>{{ $t('loginPage.pages.register.success') }}</p>
      <p>
        <router-link to="/login">
          {{ $t('loginPage.pages.register.goToLogin') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import validator_rules from '../../../helpers/validator_rules';

export default {
  data: ({ $i18n, currentPassword }) => ({
    acceptHasError: false,
    acceptInterval: null,
    model: {
      name: '',
      email: '',
      password: '',
      master: '',
      country: '',
      state: '',
    },
    brazilID: '',
    states: [],
    valid: true,
    registered: false,
    error: null,
    showPassword: false,
    showPasswordConfirmation: false,
    passwordConfirmation: '',
    rules: {
      email: validator_rules.email(
        'true',
        $i18n.t('loginPage.pages.register.invalids.email')
      ),
      name: [
        v => !!v || $i18n.t('loginPage.pages.register.invalids.name'),
        v => v.length >= 3 || $i18n.t('loginPage.pages.register.invalids.name'),
      ],
      password: [
        v => !!v || $i18n.t('loginPage.pages.register.invalids.password'),
        v =>
          v.length >= 6 ||
          $i18n.t('loginPage.pages.register.invalids.password'),
      ],
      passwordConfirmation: [
        v =>
          !!v ||
          $i18n.t('loginPage.pages.register.invalids.passwordConfirmation'),
        v =>
          v.length >= 6 ||
          $i18n.t('loginPage.pages.register.invalids.passwordConfirmation'),
        v =>
          v === currentPassword() ||
          $i18n.t(
            'loginPage.pages.register.invalids.passwordConfirmationDifferent'
          ),
      ],
      country: [
        v => !!v || $i18n.t('loginPage.pages.register.invalids.country'),
      ],
      state: [v => !!v || $i18n.t('loginPage.pages.register.invalids.state')],
      master: [v => !!v || $i18n.t('loginPage.pages.register.invalids.master')],
      accept: [v => v || ''],
    },
  }),
  mounted() {
    this.$store.dispatch('country/loadList', { has_masters: true });
    this.acceptInterval = setInterval(() => {
      try {
        if (this.$refs['checkbox-accept'].rippleState == 'error') {
          this.acceptHasError = true;
        } else {
          this.acceptHasError = false;
        }
      } catch (e) {}
    }, 1000);
  },
  methods: {
    async getStates() {
      this.states = [];
      const result = await this.$store.dispatch(
        'country/listStates',
        this.model.country
      );
      if (result.error != true) {
        this.states = result;
      }
    },
    async submit() {
      const payload = {
        ...this.model,
        lang: this.$i18n.locale,
      };
      if (this.$refs.form.validate()) {
        const result = await this.$store.dispatch('user/register', payload);
        this.$gtm.trackEvent({
          category: 'create-account',
          event: 'click',
          action: 'create-account-click',
          label: 'btn-create-account',
        });

        this.registered = +result.status === 1;
        if (!this.registered) {
          if (result.data.message == 'duplicated-email') {
            this.$store.commit('snackbar/show', {
              content: this.$i18n.t('loginPage.pages.register.errorDuplicated'),
              type: 'error',
            });
          } else {
            this.$store.commit('snackbar/show', {
              content: this.$i18n.t('loginPage.pages.register.error'),
              type: 'error',
            });
          }
        }
      }
    },
    currentPassword() {
      return this.model.password;
    },
  },
  computed: {
    countryOptions() {
      const countries = this.$store.state.country.list;
      if (typeof countries.count != 'undefined' && countries.count > 0) {
        var list = countries.items.map(country => {
          return {
            _id: country._id,
            name:
              country.name[this.$i18n.locale] == 'NULL'
                ? country.name['en']
                : country.name[this.$i18n.locale],
          };
        });
        return list;
      }
    },
    masterOptions() {
      const country = this.$store.state.country.list.items.find(item => {
        return item._id === this.model.country;
      });
      if (country) {
        return country.masters.map(master => {
          return {
            value: master._id,
            text: master.title,
          };
        });
      }
      return [];
    },
    sending() {
      const sending = this.$store.getters['user/getSending'];
      return sending;
    },
  },
  destroyed() {
    clearInterval(this.acceptInterval);
  },
};
</script>

<style lang="scss" scoped>
.has-error {
  color: red;
}
.hacked-message {
  margin-top: -16px;
  padding-left: 12px;
  margin-bottom: 12px;
}
#login-div {
  width: 300px;
  margin: 60px auto 0 auto;

  .err {
    background-color: #ff5b5b;
    color: #fff;
    padding: 0 15px;
    border-radius: 5px;
    margin-bottom: 30px;
    max-height: 0;
    transition: all 0.5s;
    opacity: 0;

    &.active {
      max-height: 100px;
      padding: 10px 15px;
      opacity: 1;
    }
  }

  .back {
    margin-top: 35px;
    font-size: 13px;
    cursor: pointer;

    i {
      font-size: 18px;
      margin-top: -3px;
      margin-right: 5px;
    }
  }
}
</style>
