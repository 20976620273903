<template>
  <f-container topBottom>
    <v-card elevation="0">
      <v-card-text>
        <f-privacy-text />
      </v-card-text>
    </v-card>
  </f-container>
</template>

<script lang="js">
import FPrivacyText from "../../components/PrivacyPolicyText.vue";

export default {
  components: {
    FPrivacyText
  }
};
</script>
