<template>
  <f-wrapper page="register">
    <f-login-container>
      <h1>{{ $t('loginPage.pages.register.title') }}</h1>
    </f-login-container>

    <f-card>
      <f-register-form />
    </f-card>
  </f-wrapper>
</template>

<script>
import FWrapper from '../../components/views/login/Wrapper.vue';
import FCard from '../../components/shared/Card.vue';
import FLoginContainer from '../../components/views/login/LoginContainer.vue';
import FRegisterForm from '../../components/views/login/RegisterForm.vue';

export default {
  components: {
    FWrapper,
    FCard,
    FLoginContainer,
    FRegisterForm,
  },
};
</script>
