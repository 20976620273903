<template>
  <div class="login-wrapper">
    <div v-if="showBack" class="login-wrapper__header">
      <router-link to="/login">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("loginPage.back") }}
      </router-link>
    </div>

    <slot />

    <f-language-selector />
  </div>
</template>

<script>
import FLanguageSelector from "./LanguageSelector.vue";

export default {
  components: {
    FLanguageSelector
  },
  props: {
    page: String
  },
  computed: {
    showBack() {
      return this.page !== "login";
    }
  }
};
</script>

<style lang="scss">
.login-wrapper {
  margin: 30px 0 80px 0;
  &__header {
    margin: 0 0 20px 0;
  }

  .err,
  .info {
    background-color: #ff5b5b;
    color: #fff;
    padding: 0 15px;
    border-radius: 5px;
    margin-bottom: 30px;
    max-height: 0;
    transition: all 0.5s;
    opacity: 0;

    &.active {
      max-height: 100px;
      padding: 10px 15px;
      opacity: 1;
    }
  }

  .info {
    background-color: #ddd !important;
    color: black !important;
    &.active {
      max-height: initial;
    }
  }
}
</style>
