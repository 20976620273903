<template>
  <div class="login-links__languages">
    <div @click="setLanguage('en')" :class="{ active: $i18n.locale == 'en' }">
      EN
    </div>
    <div @click="setLanguage('es')" :class="{ active: $i18n.locale == 'es' }">
      ES
    </div>
    <div @click="setLanguage('pt')" :class="{ active: $i18n.locale == 'pt' }">
      PT
    </div>
  </div>
</template>

<script>
export default {
  created() {
    localStorage.currentLang = 'en';
  },
  methods: {
    setLanguage(lang) {
      localStorage.currentLang = lang;
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
      this.$gtm.trackEvent({
        category: 'language',
        event: 'select',
        action: 'language-select',
        label: 'seletor-language',
        value: lang,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base/variables';

.login-links {
  margin: 35px 0;
  &__languages {
    text-align: center;
    margin-top: 30px;

    & > div {
      font-size: 12px;
      display: inline-block;
      padding: 5px 12px;
      margin: 0px;
      background-color: $box-bg;
      cursor: pointer;
      border-right: 1px solid $box-selected;

      &:hover {
        background-color: $box-hover;
        color: $highlight;
      }
      &.active {
        background-color: $box-selected;
        color: $highlight;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
</style>
