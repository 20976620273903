<template>
  <f-wrapper page="reset">
    <f-login-container>
      <h1>{{ $t('loginPage.pages.reset.title') }}</h1>
    </f-login-container>

    <f-card>
      <div v-if="!updated">
        <div v-if="error" class="err" :class="{ active: error }">
          {{ $t('loginPage.pages.reset.error') }}
        </div>
        <div>
          {{ $t('loginPage.pages.reset.text') }}
          <br />
        </div>
        <br />
        <v-text-field
          outlined
          name="reset_pass"
          :label="$tt.capitalize($t('loginPage.pages.reset.newPass'))"
          v-model="auth.password"
          type="password"
        ></v-text-field>

        <v-text-field
          outlined
          name="reset_confirm"
          :label="$tt.capitalize($t('loginPage.pages.reset.confirmPass'))"
          v-model="auth.confirmPassword"
          type="password"
        ></v-text-field>

        <v-btn
          depressed
          block
          color="primary"
          @click="reset"
          :disabled="sending"
          :loading="sending"
        >
          {{ $t('labels.change') }}
        </v-btn>
      </div>
      <div v-else>
        <p>{{ $t('loginPage.pages.reset.success') }}</p>
        <p>
          <router-link to="/login">
            {{ $t('loginPage.pages.reset.goToLogin') }}
          </router-link>
        </p>
      </div>
    </f-card>
  </f-wrapper>
</template>

<script>
import FWrapper from '../../components/views/login/Wrapper.vue';
import FCard from '../../components/shared/Card.vue';
import FLoginContainer from '../../components/views/login/LoginContainer.vue';

export default {
  components: {
    FWrapper,
    FCard,
    FLoginContainer,
  },
  data: () => ({
    auth: {
      password: '',
      confirmPassword: '',
    },
    error: false,
    updated: false,
  }),
  methods: {
    async reset() {
      let resetGtmData = {};
      this.error = false;
      const errors = [];

      if (this.auth.password.trim().length < 6) {
        errors.push(this.$t('loginPage.pages.reset.invalidPassword'));
      }

      if (this.auth.password !== this.auth.confirmPassword) {
        errors.push(this.$t('loginPage.pages.reset.invalidConfirmation'));
      }

      if (errors.length > 0) {
        this.$store.commit('snackbar/show', {
          content: errors.join(', '),
          type: 'error',
        });
        return;
      }

      this.$gtm.trackEvent({
        category: 'change-password',
        event: 'click',
        action: 'change-password-click',
        label: 'btn-change-password',
      });

      const result = await this.$store.dispatch('user/changePassword', {
        password: this.auth.password,
      });

      if (result) {
        resetGtmData = {
          category: 'change-password',
          event: 'response',
          action: 'change-password-response',
          label: 'change-password-response-success',
          value: 1,
        };
        this.updated = true;
      } else {
        resetGtmData = {
          category: 'change-password',
          event: 'response',
          action: 'change-password-response',
          label: 'change-password-response-fail',
          value: 0,
        };
        this.error = true;
      }

      this.$gtm.trackEvent(resetGtmData);
    },
    back() {
      this.$router.push({
        name: 'login',
      });
    },
  },
  computed: {
    sending() {
      const sending = this.$store.getters['user/getSending'];
      return sending;
    },
  },
};
</script>
